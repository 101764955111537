import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./auth/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
      // canActivate: [AuthGuard], // Protect the route with the AuthGuard
      // data: { moduleName: 'Dashboard' }, // Pass the module name for access checking
  },
  {
    path: 'employee-list',
    loadChildren: () =>
      import('./pages/employee-list/employee-list.module').then(
        (m) => m.EmployeeListPageModule
      ),
    canActivate: [AuthGuard], // Protect the route with the AuthGuard
    data: { moduleName: 'EmployeeList' }, // Pass the module name for access checking
  },
  {
    path: 'user-groups',
    loadChildren: () =>
      import('./pages/user-groups/user-groups.module').then(
        (m) => m.UserGroupsPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'UserGroups' },
  },
  {
    path: 'teams',
    loadChildren: () =>
      import('./pages/teams/teams.module').then((m) => m.TeamsPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'Teams' },
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./pages/tasks/tasks.module').then((m) => m.TasksPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'Tasks' },
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./pages/projects/projects.module').then(
        (m) => m.ProjectsPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Projects' },
  },
  {
    path: 'dsr',
    loadChildren: () =>
      import('./pages/dsr/dsr.module').then((m) => m.DsrPageModule),
  },
  {
    path: 'project-details/:id',
    loadChildren: () =>
      import('./pages/project-details/project-details.module').then(
        (m) => m.ProjectDetailsPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'attendance',
    loadChildren: () =>
      import('./pages/attendance/attendance.module').then(
        (m) => m.AttendancePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Attendance' },
  },
  {
    path: 'salary',
    loadChildren: () =>
      import('./pages/salary/salary.module').then((m) => m.SalaryPageModule),
  },
  {
    path: 'employee-onboarding/:id',
    loadChildren: () =>
      import('./pages/employee-onboarding/employee-onboarding.module').then(
        (m) => m.EmployeeOnboardingPageModule
      ),
    canActivate: [AuthGuard], // Protect the route with the AuthGuard
    data: { moduleName: 'EmployeeList' },
  },
  {
    path: 'project-manage/:id',
    loadChildren: () =>
      import('./pages/project-manage/project-manage.module').then(
        (m) => m.ProjectManagePageModule
      ),
  },
  {
    path: 'leaves',
    loadChildren: () =>
      import('./pages/leaves/leaves.module').then((m) => m.LeavesPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'Leaves' },
  },
  {
    path: 'expenses',
    loadChildren: () =>
      import('./pages/expenses/expenses.module').then(
        (m) => m.ExpensesPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Expenses' },
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./pages/feedback/feedback.module').then(
        (m) => m.FeedbackPageModule
      ),
  },
  {
    path: 'dsr-monthly-preview',
    loadChildren: () =>
      import('./pages/dsr-monthly-preview/dsr-monthly-preview.module').then(
        (m) => m.DsrMonthlyPreviewPageModule
      ),
  },
  {
    path: 'view-all-dsr',
    loadChildren: () =>
      import('./pages/view-all-dsr/view-all-dsr.module').then(
        (m) => m.ViewAllDsrPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'ViewAllDSR' },
  },
  {
    path: 'employee-profile',
    loadChildren: () =>
      import('./pages/employee-profile/employee-profile.module').then(
        (m) => m.EmployeeProfilePageModule
      ),
  },
  {
    path: 'project-task-details',
    loadChildren: () =>
      import('./modals/project-task-details/project-task-details.module').then(
        (m) => m.ProjectTaskDetailsPageModule
      ),
  },
  {
    path: 'project-epic-details',
    loadChildren: () =>
      import('./modals/project-epic-details/project-epic-details.module').then(
        (m) => m.ProjectEpicDetailsPageModule
      ),
  },
  {
    path: 'project-story-details',
    loadChildren: () =>
      import(
        './modals/project-story-details/project-story-details.module'
      ).then((m) => m.ProjectStoryDetailsPageModule),
  },
  {
    path: 'new-post',
    loadChildren: () =>
      import('./pages/new-post/new-post.module').then(
        (m) => m.NewPostPageModule
      ),
  },
  {
    path: 'organization-structure',
    loadChildren: () =>
      import(
        './pages/organization-structure/organization-structure.module'
      ).then((m) => m.OrganizationStructurePageModule),
  },
  {
    path: 'performance',
    loadChildren: () =>
      import('./pages/performance/performance.module').then(
        (m) => m.PerformancePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Performance' },
  },
  {
    path: 'view-all-kra',
    loadChildren: () =>
      import('./pages/view-all-kra/view-all-kra.module').then(
        (m) => m.ViewAllKraPageModule
      ),
  },
  {
    path: 'add-kra',
    loadChildren: () =>
      import('./pages/add-kra/add-kra.module').then((m) => m.AddKraPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'Performance' },
  },
  {
    path: 'edit-kra',
    loadChildren: () =>
      import('./pages/edit-kra/edit-kra.module').then(
        (m) => m.EditKraPageModule
      ),
  },
  {
    path: 'add-note',
    loadChildren: () =>
      import('./modals/add-note/add-note.module').then(
        (m) => m.AddNotePageModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./modals/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'project-sprint-details',
    loadChildren: () =>
      import(
        './modals/project-sprint-details/project-sprint-details.module'
      ).then((m) => m.ProjectSprintDetailsPageModule),
  },
  {
    path: 'client-dashboard',
    loadChildren: () =>
      import('./pages/client-dashboard/client-dashboard.module').then(
        (m) => m.ClientDashboardPageModule
      ),
  },

  {
    path: 'client-project-details/:id',
    loadChildren: () =>
      import(
        './pages/client-project-details/client-project-details.module'
      ).then((m) => m.ClientProjectDetailsPageModule),
  },
  {
    path: 'sales/customers',
    loadChildren: () =>
      import('./pages/sales/customers/customers.module').then(
        (m) => m.CustomersPageModule
      ),
  },
  {
    path: 'customers-details',
    loadChildren: () =>
      import('./pages/sales/customers-details/customers-details.module').then(
        (m) => m.CustomersDetailsPageModule
      ),
  },
  {
    path: 'view-all-leave',
    loadChildren: () =>
      import('./pages/view-all-leave/view-all-leave.module').then(
        (m) => m.ViewAllLeavePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'ViewAllLeave' },
  },
  {
    path: 'chatrooms',
    loadChildren: () =>
      import('./pages/chatrooms/chatrooms.module').then(
        (m) => m.ChatroomsPageModule
      ),
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./pages/inventory/inventory.module').then(
        (m) => m.InventoryPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Inventory' },
  },
  {
    path: 'add-inventory',
    loadChildren: () =>
      import('./pages/inventory/add-inventory/add-inventory.module').then(
        (m) => m.AddInventoryPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Inventory' },
  },
  {
    path: 'assign-inventory-item',
    loadChildren: () =>
      import(
        './modals/assign-inventory-item/assign-inventory-item.module'
      ).then((m) => m.AssignInventoryItemPageModule),
  },
  {
    path: 'ats',
    loadChildren: () =>
      import('./pages/ats/ats.module').then((m) => m.AtsPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'ATSCVPool' },
  },
  {
    path: 'add-cv',
    loadChildren: () =>
      import('./pages/ats/add-cv/add-cv.module').then((m) => m.AddCVPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'ATSCVPool' },
  },
  {
    path: 'user-attendance',
    loadChildren: () =>
      import('./pages/attendance/user-attendance/user-attendance.module').then(
        (m) => m.UserAttendancePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'UserAttendance' },
  },
  {
    path: 'project-chart-list',
    loadChildren: () =>
      import('./modals/project-chart-list/project-chart-list.module').then(
        (m) => m.ProjectChartListPageModule
      ),
  },
  {
    path: 'project-type-list',
    loadChildren: () =>
      import('./modals/project-type-list/project-type-list.module').then(
        (m) => m.ProjectTypeListPageModule
      ),
  },
  {
    path: 'all-user-leave-admin',
    loadChildren: () =>
      import('./pages/all-user-leave-admin/all-user-leave-admin.module').then(
        (m) => m.AllUserLeaveAdminPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'LeaveReport' },
  },
  {
    path: 'tickets',
    loadChildren: () =>
      import('./pages/tickets/tickets.module').then((m) => m.TicketsPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'Tickets' },
  },
  {
    path: 'raised-ticket',
    loadChildren: () =>
      import('./modals/raised-ticket/raised-ticket.module').then(
        (m) => m.RaisedTicketPageModule
      ),
  },
  {
    path: 'view-log',
    loadChildren: () =>
      import('./pages/view-log/view-log.module').then(
        (m) => m.ViewLogPageModule
      ),
  },
  {
    path: 'conveyance',
    loadChildren: () =>
      import('./pages/conveyance/conveyance.module').then(
        (m) => m.ConveyancePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Leaves' },
  },
  {
    path: 'create-conveyance',
    loadChildren: () =>
      import('./modals/create-conveyance/create-conveyance.module').then(
        (m) => m.CreateConveyancePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'Leaves' },
  },
  {
    path: 'pmo/monthlyconsting',
    loadChildren: () =>
      import('./pages/pmo/monthlyconsting/monthlyconsting.module').then(
        (m) => m.MonthlyconstingPageModule
      ),
  },
  {
    path: 'pmo/capacity',
    loadChildren: () =>
      import('./pages/pmo/capacity/capacity.module').then(
        (m) => m.CapacityPageModule
      ),
  },
  {
    path: 'leadgenerate',
    loadChildren: () =>
      import('./pages/leadgenerate/leadgenerate.module').then(
        (m) => m.LeadgeneratePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'LeadGeneration' },
  },
  {
    path: 'holidays',
    loadChildren: () =>
      import('./pages/holidays/holidays.module').then(
        (m) => m.HolidaysPageModule
      ),
  },
  {
    path: 'raise-ticket',
    loadChildren: () =>
      import('./pages/raise-ticket/raise-ticket.module').then(
        (m) => m.RaiseTicketPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'UserTickets' },
  },
  {
    path: 'ticket-form',
    loadChildren: () =>
      import('./pages/ticket-form/ticket-form.module').then(
        (m) => m.TicketFormPageModule
      ),
  },
  {
    path: 'add-comments',
    loadChildren: () =>
      import('./modals/add-comments/add-comments.module').then(
        (m) => m.AddCommentsPageModule
      ),
  },
  {
    path: 'quality-assurance-documents',
    loadChildren: () =>
      import(
        './pages/quality-assurance-documents/quality-assurance-documents.module'
      ).then((m) => m.QualityAssuranceDocumentsPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'QualityAssurance' },
  },
  {
    path: 'lead-admin',
    loadChildren: () =>
      import('./pages/lead-admin/lead-admin.module').then(
        (m) => m.LeadAdminPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'LeadAdmin' },
  },
  {
    path: 'generate-report',
    loadChildren: () =>
      import('./pages/generate-report/generate-report.module').then(
        (m) => m.GenerateReportPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'LeadGeneration' },
  },
  {
    path: 'month-attendance',
    loadChildren: () =>
      import('./pages/month-attendance/month-attendance.module').then(
        (m) => m.MonthAttendancePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'MonthlyAttendance' },
  },
  {
    path: 'release-feature',
    loadChildren: () =>
      import('./pages/release-feature/release-feature.module').then(
        (m) => m.ReleaseFeaturePageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'ReleaseFeature' },
  },

  // appraisal module pages Ankit

  {
    path: 'appraisal-hr-screen',
    loadChildren: () =>
      import(
        './pages/appraisalFeature/appraisal-hr-screen/appraisal-hr-screen.module'
      ).then((m) => m.AppraisalHrScreenPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'HrAppraisal' },
  },

  {
    path: 'workflow-setup',
    loadChildren: () =>
      import(
        './pages/appraisalFeature/workflow-setup/workflow-setup.module'
      ).then((m) => m.WorkflowSetupPageModule),
  },
  {
    path: 'my-appraisal',
    loadChildren: () =>
      import('./pages/appraisalFeature/my-appraisal/my-appraisal.module').then(
        (m) => m.MyAppraisalPageModule
      ),
    canActivate: [AuthGuard],
    data: { moduleName: 'MyAppraisal' },
  },
  {
    path: 'emp-self-evaluation/:id',
    loadChildren: () =>
      import(
        './pages/appraisalFeature/emp-self-evaluation/emp-self-evaluation.module'
      ).then((m) => m.EmpSelfEvaluationPageModule),
  },
  {
    path: 'apraisal-request',
    loadChildren: () =>
      import(
        './pages/appraisalFeature/apraisal-request/apraisal-request.module'
      ).then((m) => m.ApraisalRequestPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'AppraisalRequest' },
  },
  {
    path: 'manager-review-form/:id/:topManager',
    loadChildren: () =>
      import(
        './pages/appraisalFeature/manager-review-form/manager-review-form.module'
      ).then((m) => m.ManagerReviewFormPageModule),
  },
  {
    path: 'employee-cost',
    loadChildren: () =>
      import('./pages/employee-cost/employee-cost.module').then(
        (m) => m.EmployeeCostPageModule
      ),  canActivate: [AuthGuard],
      data: { moduleName: 'EmployeeCost' },
  },
  {
    path: 'reject-dsr',
    loadChildren: () =>
      import('./modals/reject-dsr/reject-dsr.module').then(
        (m) => m.RejectDsrPageModule
      ),
  },
  {
    path: 'process-flow',
    loadChildren: () =>
      import('./pages/process-flow/process-flow.module').then(
        (m) => m.ProcessFlowPageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'iRACE' },
  },
  {
    path: 'open-positions',
    loadChildren: () =>
      import('./pages/open-positions/open-positions.module').then(
        (m) => m.OpenPositionsPageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'openPositions' },
  },
  {
    path: 'lead-generate-report',
    loadChildren: () =>
      import('./pages/lead-generate-report/lead-generate-report.module').then(
        (m) => m.LeadGenerateReportPageModule
      ),
  },
  {
    path: 'diagram-component',
    loadChildren: () =>
      import('./pages/diagram-component/diagram-component.module').then(
        (m) => m.DiagramComponentPageModule
      ),
  },
  {
    path: 'project-dashboard',
    loadChildren: () =>
      import(
        './pages/project-sprint-dashboard/project-sprint-dashboard.module'
      ).then((m) => m.ProjectSprintDashboardPageModule), canActivate: [AuthGuard],
      data: { moduleName: 'ProjectDashboard' },
  },
  {
    path: 'release-number-task',
    loadChildren: () =>
      import('./pages/release-number-task/release-number-task.module').then(
        (m) => m.ReleaseNumberTaskPageModule
      ),
      canActivate: [AuthGuard],
      data: { moduleName: 'ReleaseFeature' },
  },
  {
    path: 'employee-cost-report',
    loadChildren: () =>
      import('./pages/employee-cost-report/employee-cost-report.module').then(
        (m) => m.EmployeeCostReportPageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'EmployeeCost' },
  },
  {
    path: 'lead-generate-status',
    loadChildren: () =>
      import('./pages/lead-generate-status/lead-generate-status.module').then(
        (m) => m.LeadGenerateStatusPageModule
      ),
  },
  {
    path: 'staffing-forecasting',
    loadChildren: () =>
      import('./pages/staffing-forecasting/staffing-forecasting.module').then(
        (m) => m.StaffingForecastingPageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'staffingForecasting' },
  },
  {
    path: 'staffing-report',
    loadChildren: () =>
      import('./pages/staffing-report/staffing-report.module').then(
        (m) => m.StaffingReportPageModule
      ),  canActivate: [AuthGuard],
      data: { moduleName: 'staffingForecasting' },
  },
  {
    path: 'employee-mis-report',
    loadChildren: () =>
      import('./pages/employee-mis-report/employee-mis-report.module').then(
        (m) => m.EmployeeMisReportPageModule
      ),canActivate: [AuthGuard],
      data: { moduleName: 'EmployeeCost' },
  },
  {
    path: 'set-skills',
    loadChildren: () =>
      import('./pages/set-skills/set-skills.module').then(
        (m) => m.SetSkillsPageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'setSkills' },
  },
  {
    path: 'monthly-performance',
    loadChildren: () =>
      import('./pages/monthly-performance/monthly-performance.module').then(
        (m) => m.MonthlyPerformancePageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'monthlyPerformance' },
  },
  {
    path: 'monthly-performance-report',
    loadChildren: () =>
      import(
        './pages/monthly-performance-report/monthly-performance-report.module'
      ).then((m) => m.MonthlyPerformanceReportPageModule),  canActivate: [AuthGuard],
      data: { moduleName: 'monthlyPerformance' },
  },
  {
    path: 'grn',
    loadChildren: () =>
      import('./pages/grn/grn.module').then((m) => m.GrnPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'assetTracking' },
  },
  {
    path: 'delivery',
    loadChildren: () =>
      import('./pages/delivery/delivery.module').then(
        (m) => m.DeliveryPageModule
      ),
      canActivate: [AuthGuard],
      data: { moduleName: 'assetTracking' },
  },

  {
    path: 'add-comments',
    loadChildren: () =>
      import('./modals/add-comments/add-comments.module').then(
        (m) => m.AddCommentsPageModule
      ),
  },
  {
    path: 'asset-grid-view',
    loadChildren: () =>
      import('./pages/asset-grid-view/asset-grid-view.module').then(
        (m) => m.AssetGridViewPageModule
      ),  canActivate: [AuthGuard],
      data: { moduleName: 'assetTracking' },
  },
  {
    path: 'scrap-management',
    loadChildren: () =>
      import('./pages/scrap-management/scrap-management.module').then(
        (m) => m.ScrapManagementPageModule
      ), canActivate: [AuthGuard],
      data: { moduleName: 'assetTracking' },
  },

  {
    path: 'asset',
    loadChildren: () =>
      import('./pages/fixed-asset/asset.module').then((m) => m.AssetPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'assetTracking' },
  },
  {
    path: 'faulty-product',
    loadChildren: () =>
      import('./pages/faulty-product/faulty-product.module').then(
        (m) => m.FaultyProductPageModule
      ),
      canActivate: [AuthGuard],
      data: { moduleName: 'assetTracking' },
  },
  {
    path: 'asset-grid-view',
    loadChildren: () =>
      import('./pages/asset-grid-view/asset-grid-view.module').then(
        (m) => m.AssetGridViewPageModule
      ),
      canActivate: [AuthGuard],
      data: { moduleName: 'assetTracking' },
  },
  {
    path: 'quality-assurance',
    loadChildren: () =>
      import('./pages/quality-assurance/quality-assurance.module').then(
        (m) => m.QualityAssurancePageModule
      ),
      canActivate: [AuthGuard],
      data: { moduleName: 'assetTracking' },
  },
  {
    path: 'project-execution',
    loadChildren: () =>
      import('./pages/project-execution/project-execution.module').then(
        (m) => m.ProjectExecutionPageModule
      ),
      canActivate: [AuthGuard],
      data: { moduleName: 'ProjectExecution' },
  },
  {
    path: 'lead-track',
    loadChildren: () =>
      import('./pages/lead-tracking/lead-tracking.module').then(
        (m) => m.LeadTrackingPageModule
      ),
  },
  {
    path: 'irace-repository',
    loadChildren: () => import('./pages/irace-repository/irace-repository.module').then( m => m.IraceRepositoryPageModule),
    canActivate: [AuthGuard],
    data: { moduleName: 'iRACE' },
  },


  // appraisal module pages Ankit

  // {
  //     path: '**',
  //     loadChildren: () => import('./pages/no-page/no-page.module').then( m => m.NoPagePageModule)
  //   },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
